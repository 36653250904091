import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger.js';

export interface AiWidgetOptions {
  projectId: string;
  id: string;
  scopePathStringified: string;
}

interface AiWidgetResponse {
  rows: Record<string, unknown>[];
  success: boolean;
  error?: string;
}

type DataReturn = (data: AiWidgetResponse) => void;

interface KOptions {
  callBack?: DataReturn;
  errorCallBack?: (error: unknown) => void;
  setLoading?: (loading: boolean) => void;
  params: AiWidgetOptions;
  uid?: string;
}
export const getAiWidgetData = ({ callBack, errorCallBack, setLoading, params, uid }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const getWidgetData = httpsCallable(functions, 'getWidgetData', { timeout: 700_000 });

  requestLog.log(`getWidgetData({data:${JSON.stringify({ ...params, uid })}})`);

  void getWidgetData(params)
    .then((result) => {
      // console.log("loading", params.q, loading);
      if (typeof setLoading === 'function') {
        setLoading(false);
      }
      if (callBack) {
        callBack(result.data as AiWidgetResponse);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};
