/* eslint-disable no-console */
// methods like c*nsole.log are typically used in development
// -> this is why our linter warns us about not leaving c*nsole.method calls in our
//
// however: sometimes we desire to actually log something and leave in our code.
// this convenience export works around two issues.
// 1) having to disable lint warnings for each of those occurences
// 2) clearing up the codebase so we can easily search for "c*nsole.log"
//    and remove those dev logs

import { blueGrey, red } from '@mui/material/colors';

import { matrixDataColorLight } from '../Screens/defaults.js';

// TL;DR:
// logger should only be used for messages that SHOULD be logged in production too
// dev logging should still use c*nsole.method

// introduce enable/disable logging to reduce noise in tests
let isEnabled = true;

export const toggleAppLoadingLog = (toggle: boolean) => (isEnabled = toggle);

export const logger: Console = {
  ...console,
  group: (...data) => {
    if (isEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return console.group(...data);
    }
  },
  groupEnd: () => {
    if (isEnabled) {
      return console.groupEnd();
    }
  },
  error: (...data) => {
    if (isEnabled) {
      return console.error(
        '%cKaepla',
        `background-color: ${red[500]}; padding: 0.4em; border-radius: 0.3em;`,
        ...data,
      );
    }
  },
  info: (...data) => {
    if (isEnabled) {
      return console.info(
        '%cKaepla',
        `background-color: ${blueGrey[300]}; padding: 0.4em; border-radius: 0.3em;`,
        ...data,
      );
    }
  },
  debug: (...data) => {
    if (isEnabled) {
      return console.debug(
        '%cKaepla',
        `background-color: ${red[300]}; padding: 0.4em; border-radius: 0.3em;`,
        ...data,
      );
    }
  },
  log: (...data) => {
    if (isEnabled) {
      return console.log(
        '%cKaepla',
        `background-color: ${matrixDataColorLight}; padding: 0.4em; border-radius: 0.3em;`,
        ...data,
      );
    }
  },
};

let isAuthLogEnabled = true;

export const toggleAuthLog = (toggle: boolean) => (isAuthLogEnabled = toggle);

export const authLog: Console = {
  ...logger,
  log: (...data) => {
    if (isAuthLogEnabled) {
      return logger.log('=auth=', ...data);
    }
  },
};

let isErrorLogEnabled = true;

export const toggleErrorLog = (toggle: boolean) => (isErrorLogEnabled = toggle);

export const errorLog: Console = {
  ...logger,
  error: (...data) => {
    if (isErrorLogEnabled) {
      return logger.error('=error=', ...data);
    }
  },
};

let isLayoutLogEnabled = false;

export const toggleLayoutLog = (toggle: boolean) => (isLayoutLogEnabled = toggle);

export const layoutLog: Console = {
  ...logger,
  log: (...data) => {
    if (isLayoutLogEnabled) {
      return logger.log('=layout=', ...data);
    }
  },
};

let isRequestLogEnabled = true;

export const toggleRequestLog = (toggle: boolean) => (isRequestLogEnabled = toggle);

export const requestLog: Console = {
  ...logger,
  log: (...data) => {
    if (isRequestLogEnabled) {
      return logger.log('=request=', ...data);
    }
  },
};

let isAiLogEnabled = true;

export const toggleAiLog = (toggle: boolean) => (isAiLogEnabled = toggle);

export const aiLog: Console = {
  ...logger,
  log: (...data) => {
    if (isAiLogEnabled) {
      return logger.log('=ai=', ...data);
    }
  },
  warn: (...data) => {
    if (isAiLogEnabled) {
      return logger.warn('=ai=', ...data);
    }
  },
  error: (...data) => {
    if (isAiLogEnabled) {
      return logger.error('=ai=', ...data);
    }
  },
};

let isParseErrorLogEnabled = true;

export const toggleParseErrorLog = (toggle: boolean) => (isParseErrorLogEnabled = toggle);

export const parseErrorLog: Console = {
  ...logger,
  log: (...data) => {
    if (isParseErrorLogEnabled) {
      return logger.log('=parseError=', ...data);
    }
  },
};

let isToDoLogEnabled = true;

export const toggleToDoLog = (toggle: boolean) => (isToDoLogEnabled = toggle);

export const toDoLog: Console = {
  ...logger,
  log: (...data) => {
    if (isToDoLogEnabled) {
      return logger.log('=TODO=', ...data);
    }
  },
};

let isMfaLogEnabled = true;

export const toggleMfaLog = (toggle: boolean) => (isMfaLogEnabled = toggle);

export const mfaLog: Console = {
  ...logger,
  log: (...data) => {
    if (isMfaLogEnabled) {
      return logger.log('=mFa=', ...data);
    }
  },
};
