import { KaeplaBranding } from '../../services/kaeplaTypes/Application/KaeplaBranding.js';

export const documentTitle = (branding: KaeplaBranding | undefined) => {
  let title = 'Kaepla, a service by Atrigam';
  let forBy = '';
  if (branding?.appRealm && branding?.appProvider) {
    if (branding?.appRealm === branding?.appProvider) {
      forBy = ` by ${branding?.appProvider}`;
    } else {
      forBy = ` for ${branding?.appRealm} by ${branding?.appProvider}`;
    }
  }
  if (branding?.appName && branding?.appRealm && branding?.appProvider) {
    title = `${branding?.appName}${forBy}`;
  }
  return title;
};
