import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthReactProvider.js';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener.js';
import { KaeplaCustomerAssignment } from '../../services/kaeplaTypes/Application/KaeplaCustomerAssignment.js';
import { KaeplaDomainType } from '../../services/kaeplaTypes/Application/KaeplaDomainType.js';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState.js';
import { customerAssignmentState } from '../../services/recoil/nonpersistent/customerAssignmentState.js';
import { knownCustomersState } from '../../services/recoil/nonpersistent/knownCustomersState.js';
import { brandingState } from '../../services/recoil/persistent/brandingState.js';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState.js';
import { UnhandledCaseError } from '../helpers/UnhandledCaseError.js';
import { getCustomersFromCustomerIds } from '../helpers/getCustomersFromCustomerIds.js';
import { logger } from '../helpers/logger.js';

export const CustomerAssignmentsListenerUsers = () => {
  const { kaeplaUser } = useAuth();
  const branding = useRecoilValue(brandingState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const setCustomerAssignments = useSetRecoilState(customerAssignmentState);
  const setKnownCustomers = useSetRecoilState(knownCustomersState);
  const [app, setApp] = useRecoilState(applicationState);

  const loadCustomersForAssignments = useCallback(
    async (customerAssignments: KaeplaCustomerAssignment[]) => {
      setCustomerAssignments(customerAssignments);

      const customerIds = customerAssignments.map((c) => c.customerId);
      const customers = await getCustomersFromCustomerIds({ customerIds });

      logger.log('CustomerAssignmentsListenerUsers setKnownCustomers', customers);
      setKnownCustomers(customers);

      logger.log('CustomerAssignmentsListenerUsers setApp customersLoaded => true');
      setApp((previousApp) => ({ ...previousApp, customersLoaded: true }));
    },
    [setApp, setCustomerAssignments, setKnownCustomers],
  );

  const customerAssignmentsListener = useCallback(() => {
    if (kaeplaAssignment ?? !kaeplaUser?.uid) return; // user is an admin or not authenticated yet
    if (!branding?.realmType || !branding?.appRealmId) return; // we always need a branding
    if (app.connecting) return; // we are not connected yet
    logger.log('\nuseEffect CustomerAssignmentsListenerUsers');
    const fireStorePath = `customerAssignments`;
    const queryWhere: WhereClause[] = [];
    // limit to user and complete state if not admin
    queryWhere.push(
      {
        fieldPath: 'uid',
        opStr: '==',
        value: kaeplaUser.uid,
      },
      {
        fieldPath: 'complete',
        opStr: '==',
        value: true,
      },
    );

    logger.log('branding?.realmType', branding.realmType);

    switch (branding.realmType) {
      case KaeplaDomainType.customer: {
        logger.log('query by customerId ==', branding.appRealmId);
        // TODO: how can we use KaeplaDomainType.customer?
        // customer domain lock through branding
        queryWhere.push({
          fieldPath: 'customerId',
          opStr: '==',
          value: branding.appRealmId,
        });

        break;
      }

      case KaeplaDomainType.reseller: {
        logger.log('query by resellerId ==', branding.appRealmId);
        // customer domain lock through branding
        queryWhere.push({
          fieldPath: 'resellerId',
          opStr: '==',
          value: branding.appRealmId,
        });

        break;
      }

      default: {
        throw new UnhandledCaseError(branding.realmType);
      }
    }

    return addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (customerAssignments) => {
        logger.log('CustomerAssignmentsListenerUsers fires:', customerAssignments);
        void loadCustomersForAssignments(customerAssignments as KaeplaCustomerAssignment[]);
      },
    });
  }, [
    app.connecting,
    branding?.appRealmId,
    branding?.realmType,
    kaeplaUser?.uid,
    kaeplaAssignment,
    loadCustomersForAssignments,
  ]);

  useEffect(() => {
    const unsubscribe = customerAssignmentsListener();
    return () => {
      if (unsubscribe) {
        logger.log('CustomerAssignmentsListenerUsers is unmounted!');
        unsubscribe();
      }
    };
  }, [customerAssignmentsListener]);

  return null;
};
