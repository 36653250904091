import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, Grid, useTheme } from '@mui/material';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { format } from 'sql-formatter';

import { AiWidgetPerType } from './AiWidgetPerType';
import { useAuth } from '../../../AuthReactProvider';
import { getAiWidgetData } from '../../../services/api/getAiWidgetData';
import { getAiWidgetsForRun } from '../../../services/firestore/getAiWidgetsForRun';
import { ThreadMessage, Widget } from '../../../services/kaeplaTypes';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState';
import { ExpandMore } from '../../features/ExpandMore';

export interface WidgetWithData extends Widget {
  data?: Record<string, unknown>[];
}

export const AiWidgetPreview = ({ aiMessage }: { aiMessage: ThreadMessage }) => {
  const theme = useTheme();
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [widgets, setWidgets] = useState<WidgetWithData[]>([]);
  const [toggleSql, setToggleSql] = useState(false);

  const handleExpandClick = () => {
    setToggleSql(!toggleSql);
  };

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    const loadWidgets = async () => {
      if (!aiMessage.runId) return;
      const _widgets = (await getAiWidgetsForRun({
        uid: aiMessage.uid,
        projectId: project.id,
        runId: aiMessage.runId,
      })) as unknown as WidgetWithData[];
      if (_widgets.length === 0) return;

      getAiWidgetData({
        callBack: (data) => {
          _widgets.map((widget) => {
            const _widget = widget;
            _widget.data = data.rows;
            return _widget;
          });
          setWidgets(_widgets);
        },
        params: {
          scopePathStringified: JSON.stringify(currentScopePath),
          projectId: project.id,
          id: _widgets[0].id,
        },
        uid: kaeplaUser.uid,
      });
    };
    void loadWidgets();
  }, [aiMessage.runId, aiMessage.uid, currentScopePath, kaeplaUser?.uid, project.id]);

  if (widgets.length === 0) return null;

  return widgets.map((widget) => (
    <Grid container spacing={2} key={widget.id}>
      <Grid item xs={12}>
        <AiWidgetPerType widget={widget} />
      </Grid>
      {kaeplaAssignment && (
        <Grid item xs={12} key={widget.id}>
          show widget sql (admin only)
          <ExpandMore
            expand={toggleSql}
            aria-expanded={toggleSql}
            aria-label="show sql"
            edge="end"
            sx={{ mr: -0.8 }}
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </ExpandMore>
          <Collapse in={toggleSql} timeout="auto" unmountOnExit>
            <CodeEditor
              value={format(widget.sql, { language: 'bigquery' })}
              data-color-mode={theme.palette.mode}
              language="sql"
              padding={15}
              style={{
                fontSize: 14,
                fontFamily:
                  'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
              }}
            />
          </Collapse>
        </Grid>
      )}
    </Grid>
  ));
};
