import {
  toggleAppLoadingLog,
  toggleLayoutLog,
  toggleParseErrorLog,
  toggleRequestLog,
  toggleToDoLog,
  toggleMfaLog,
} from './Frontend/helpers/logger';

toggleAppLoadingLog(true);
toggleLayoutLog(true);
toggleRequestLog(true);
toggleMfaLog(true);
// we want to see them always
toggleParseErrorLog(true);
toggleToDoLog(true);
