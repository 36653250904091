import { Paper, Typography } from '@mui/material';

import { widgetValueFormatter } from './widgetValueFormatter';
import { WidgetWithData } from '../AiMessage/AiWidgetPreview';

interface Options {
  widget: WidgetWithData;
}

export const AiScalarWidget = ({ widget }: Options) => {
  const records = widget.data;
  if (!records) return <div>no records</div>;

  const name = Object.keys(records[0])[0];
  const value = records[0];
  const valueFormatted = widgetValueFormatter(value, name);
  const output = valueFormatted as string;

  return (
    <Paper sx={{ p: 1 }}>
      <Typography sx={{ fontSize: 20, letterSpacing: 2 }}>{widget.headline}</Typography>
      <Typography sx={{ fontWeight: 600, fontSize: 18 }}>{output}</Typography>
    </Paper>
  );
};
