import { Grid } from '@mui/material';

import { AuthenticationFlow } from './AuthenticationFlow.js';
import { BrandedLoginHeader } from './BrandedLoginHeader.js';

export const CustomBrandedLogin = () => {
  return (
    <Grid container spacing={3} data-testid="login-BrandedLogin" alignItems="center">
      <Grid item xs={12}>
        <BrandedLoginHeader />
      </Grid>
      <Grid item xs={12}>
        <AuthenticationFlow />
      </Grid>
    </Grid>
  );
};
