import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { lightBlue } from '@mui/material/colors';

import { AiMessageContent } from './AiMessageContent';
import { ThreadMessage } from '../../../services/kaeplaTypes';
import { CONVERSATIONAL_UI_FONTSIZE_SM, CONVERSATIONAL_UI_FONTSIZE_SX } from '../lib/constants';

export const UserMessage = ({ aiMessage }: { aiMessage: ThreadMessage }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid container spacing={2}>
      <Grid item xs={2} />
      <Grid item xs={10}>
        <Paper
          elevation={0}
          sx={{
            py: 1,
            my: 1,
            px: 2,
            backgroundColor: lightBlue[50],
            fontSize: smUp ? CONVERSATIONAL_UI_FONTSIZE_SM : CONVERSATIONAL_UI_FONTSIZE_SX,
          }}
        >
          <AiMessageContent aiMessage={aiMessage} />
        </Paper>
      </Grid>
    </Grid>
  );
};
