export const stringToColor = (string: string) => {
  let hash = 0;
  let index;

  /* eslint-disable no-bitwise */
  for (index = 0; index < string.length; index += 1) {
    const char = string.codePointAt(index) ?? 1;
    hash = char + ((hash << 5) - hash);
  }

  let color = '#';

  for (index = 0; index < 3; index += 1) {
    const value = (hash >> (index * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
};
