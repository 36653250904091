import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger.js';
import { AiResponse } from '../kaeplaTypes/prompt/AiResponse.js';

export interface AiMessageOptions {
  projectId: string;
  message?: string;
  scopePathStringified: string;
  restartThread?: boolean;
}

type DataReturn = (data: AiResponse) => void;

interface KOptions {
  callBack?: DataReturn;
  errorCallBack?: (error: unknown) => void;
  setLoading?: (loading: boolean) => void;
  params: AiMessageOptions;
  uid?: string;
}
export const sendAiMessage = ({ callBack, errorCallBack, setLoading, params, uid }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const aiMessage = httpsCallable(functions, 'aiMessage', { timeout: 700_000 });

  // aiMessage({data:{"scopePathStringified": "[]", "question": "total turnover for all actuals in 2024", "projectId": "PID_Elmos_A1"}})
  requestLog.log(`aiMessage({data:${JSON.stringify({ ...params, uid })}})`);

  void aiMessage(params)
    .then((result) => {
      // console.log("loading", params.q, loading);
      if (typeof setLoading === 'function') {
        setLoading(false);
      }
      if (callBack) {
        callBack(result.data as AiResponse);
      }
    })
    .catch((error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};
