import { atom } from 'recoil';

import { KaeplaResellerAssignment } from '../../kaeplaTypes/Application/KaeplaResellerAssignment.js';

const defaultResellerAssignmentsState: KaeplaResellerAssignment[] = [];

export const resellerAssignmentsState = atom<KaeplaResellerAssignment[]>({
  key: 'resellerAssignments',
  default: defaultResellerAssignmentsState,
});
