import { Box, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';

import { ThreadMessageOrdered } from './AIAssistant';
import { sequence1de, sequence2de, sequence3de, sequence4de } from './waitingMessageSequencesDe';
import { sequence1en, sequence2en, sequence3en, sequence4en } from './waitingMessageSequencesEn';
import { useAuth } from '../../../AuthReactProvider';
import { AiLog } from '../../../services/kaeplaTypes/assistants/AiLog';
import { AssistantMessage } from '../AiMessage/AssistantMessage';
import { NewUserMessage } from '../AiMessage/NewUserMessage';
import { UserMessage } from '../AiMessage/UserMessage';
import { CONVERSATIONAL_UI_FONTSIZE_SM, CONVERSATIONAL_UI_FONTSIZE_SX } from '../lib/constants';

interface Options {
  message: string;
  aiMessages: ThreadMessageOrdered[];
  aiLogs: AiLog[];
  processing: boolean;
}

export const Gap = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ p: 2 }} />
    </Grid>
  );
};

const sequencesPerLanguage: Record<string, (string | number)[][]> = {
  en: [sequence1en, sequence2en, sequence3en, sequence4en],
  de: [sequence1de, sequence2de, sequence3de, sequence4de],
};

export const AIAssistantConversation = ({ message, aiMessages, processing }: Options) => {
  const { kaeplaUser } = useAuth();
  const language = (kaeplaUser?.settings?.language as unknown as string) ?? 'en';
  const sequences = sequencesPerLanguage[language];
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const lastMessage = aiMessages.at(-1);

  return (
    <Grid container spacing={0}>
      <Grid item xs={10}>
        <Box height={400} />
      </Grid>
      {aiMessages.map((aiMessage) => (
        <Grid key={aiMessage.id} item xs={12}>
          {aiMessage.gap && <Gap />}
          {aiMessage.data.role === 'user' && <UserMessage aiMessage={aiMessage} />}
          {aiMessage.data.role === 'assistant' && <AssistantMessage aiMessage={aiMessage} />}
          {/* {JSON.stringify(aiMessage)} */}
        </Grid>
      ))}
      {lastMessage?.data.role === 'user' && <Gap />}
      <NewUserMessage message={message} />
      {processing && (
        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              mt: lastMessage?.data.role === 'user' ? 0 : -1,
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              fontSize: smUp ? 30 : 20,
            }}
          >
            <TypeAnimation
              sequence={sequences[Math.floor(Math.random() * sequences.length)]}
              wrapper="span"
              speed={40}
              deletionSpeed={90}
              style={{
                fontSize: smUp ? CONVERSATIONAL_UI_FONTSIZE_SM : CONVERSATIONAL_UI_FONTSIZE_SX,
                display: 'inline-block',
              }}
              repeat={Number.POSITIVE_INFINITY}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
