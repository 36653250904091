/* eslint-disable @typescript-eslint/naming-convention */
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { widgetValueFormatter } from './widgetValueFormatter';
import { cleanColumnName } from '../../helpers/cleanColumnName';
import { WidgetWithData } from '../AiMessage/AiWidgetPreview';

interface Options {
  widget: WidgetWithData;
}

export const AiTableWidget = ({ widget }: Options) => {
  const records = widget.data;
  if (!records) return <div>no records</div>;

  const headers = Object.keys(records[0]);

  const colsMap = headers?.map((d) => ({
    accessorKey: d,
    id: d,
    header: cleanColumnName(d),
  }));

  const data: Record<string, unknown>[] = [];
  [...records].forEach((value) => {
    [...headers].forEach((header) => {
      value[header] = widgetValueFormatter(value, header);
    });
    data.push(value);
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 80 }} size="small">
        <TableHead>
          <TableRow>
            {colsMap.map((col) => (
              <TableCell sx={{ fontWeight: 'bold' }} key={col.id}>
                {col.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {colsMap.map((col) => (
                <TableCell key={`${col.id}${index}`}>{row[col.id] as string}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
