import { RefObject, useEffect, useMemo, useState } from 'react';

export const useIsVisible = (reference: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
    [],
  );

  useEffect(() => {
    if (!reference.current) return;
    observer.observe(reference.current);
    return () => observer.disconnect();
  }, [observer, reference]);

  return isIntersecting;
};
