import { ThemeOptions, lighten } from '@mui/material';

import { Typography } from './Typography.js';
import { mainColor, secondaryColor } from './colors.js';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: mainColor,
      light: lighten(mainColor, 0.8),
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: Typography,
};
