const isLocalhost =
  window.location.host.startsWith('localhost:') || window.location.host.includes('.local');

export const IS_DEV = import.meta.env.DEV;

export const IS_CI = import.meta.env.VITE_CI === 'true' || (isLocalhost && !IS_DEV);

export const IS_PROD = import.meta.env.PROD;

export const IS_TEST = import.meta.env.NODE_ENV === 'test' || IS_CI;

export const IS_DEBUG = IS_DEV || IS_PROD || IS_CI;
