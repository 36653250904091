import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect.js';

const defaultLastCustomerIdState: string | undefined = undefined;

export const lastCustomerIDState = atom<string>({
  key: 'lastCustomerId',
  default: defaultLastCustomerIdState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
