import { Box, Button, Grid, TextField } from '@mui/material';
import { PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from 'firebase/auth';
import { ChangeEvent, useEffect, useState } from 'react';

import { useAuth } from '../../../../AuthReactProvider.js';
import { kaeplaAuth } from '../../../../firebaseInit.js';
import { mfaLog } from '../../../helpers/logger.js';

export const SecondFactor = () => {
  const { setSecondFactorRequired, emailForSecondFactor, multiFactorResolver } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [verificationId, setVerificationId] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [recaptchaVerifier, setRecaptchaVerifyer] = useState<RecaptchaVerifier>();

  useEffect(() => {
    const _recaptchaVerifier = new RecaptchaVerifier(kaeplaAuth, 'recaptcha-id');
    setRecaptchaVerifyer(_recaptchaVerifier);
  }, []);

  const getVerificationCode = async () => {
    if (!recaptchaVerifier || emailForSecondFactor === '' || !multiFactorResolver) return null;

    // Specify the phone number and pass the MFA session.
    const phoneInfoOptions = {
      multiFactorHint: multiFactorResolver.hints[0],
      session: multiFactorResolver.session,
    };
    mfaLog.log('phoneInfoOptions', phoneInfoOptions);
    const phoneAuthProvider = new PhoneAuthProvider(kaeplaAuth);
    // Send SMS verification code.
    const verificationIdFromAuthProvider = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier,
    );
    mfaLog.log('verificationIdFromAuthProvider', verificationIdFromAuthProvider);
    setVerificationId(verificationIdFromAuthProvider);
  };

  const verifyWithCode = () => {
    if (verificationId === '' || verificationCode === '' || !multiFactorResolver) return;
    // Ask user for the verification code. Then:
    const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

    void multiFactorResolver
      .resolveSignIn(multiFactorAssertion)
      .then(function () {
        // User successfully signed in with the second factor phone number.
      })
      .catch(function (error: string) {
        alert(`Error completing sign in. ${error}`);
      });
  };

  return (
    <Grid
      container
      spacing={3}
      // direction="column"
      // justifyContent="center"
      alignItems="center"
    >
      <Box id="recaptcha-id" />
      {verificationId === '' && (
        <>
          <Grid item xs={12}>
            <TextField
              label="Phone Number"
              variant="standard"
              fullWidth
              value={phoneNumber}
              inputProps={{ 'data-testid': 'mfa-phone-number', autoComplete: 'off' }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setPhoneNumber(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              data-testid="mfa-button-cancel"
              onClick={() => {
                setVerificationId('');
                setSecondFactorRequired(false);
              }}
            >
              cancel
            </Button>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Button
              data-testid="mfa-button-get-code"
              variant="contained"
              onClick={() => {
                void getVerificationCode();
              }}
            >
              Get Code
            </Button>
          </Grid>
        </>
      )}

      {verificationId !== '' && (
        <>
          <Grid item xs={12}>
            <TextField
              label="Verification Code"
              variant="standard"
              value={verificationCode}
              inputProps={{
                'data-testid': 'mfa-verification-code',
                autoComplete: 'off',
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setVerificationCode(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              data-testid="mfa-button-cancel"
              onClick={() => {
                setVerificationId('');
                setSecondFactorRequired(false);
              }}
            >
              cancel
            </Button>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <Button
              data-testid="mfa-button-verify"
              variant="contained"
              onClick={() => {
                void verifyWithCode();
              }}
            >
              verify
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};
