import { ReactElement, createContext, useCallback, useContext, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAuth } from './AuthReactProvider.js';
import { createOrUpdateUserPreferences } from './services/firestore/createOrUpdateUserPreferences.js';
import { getUserPreferences } from './services/firestore/getUserPreferences.js';
import { getUserPreferencesId } from './services/firestore/getUserPreferencesId.js';
import { KaeplaUserPreferences } from './services/kaeplaTypes/Application/KaeplaUserPreferences.js';
import { projectState } from './services/recoil/nonpersistent/projectState.js';
import { userPreferencesState } from './services/recoil/nonpersistent/userPreferencesState.js';
import { currentScopePathState } from './services/recoil/persistent/currentScopePathState.js';

export interface UserPreferencesContextType {
  userPreferences: KaeplaUserPreferences[];
  getPreferences: () => KaeplaUserPreferences | undefined;
  getGlobalPreferences: () => KaeplaUserPreferences | undefined;
  setPreferences: (preferences: KaeplaUserPreferences) => void;
  setGlobalPreferences: (preferences: KaeplaUserPreferences) => void;
  getUserPreferencesFromServer: () => Promise<KaeplaUserPreferences | undefined>;
}

interface UserPreferencesProviderProperties {
  children?: React.ReactNode;
}

export interface UserPreferencesContextModel {
  userPreferences: KaeplaUserPreferences[];
  getPreferences: () => KaeplaUserPreferences | undefined;
  getGlobalPreferences: () => KaeplaUserPreferences | undefined;
  setPreferences: (preferences: KaeplaUserPreferences) => void;
  setGlobalPreferences: (preferences: KaeplaUserPreferences) => void;
  getUserPreferencesFromServer: () => Promise<KaeplaUserPreferences | undefined>;
}

export const UserPreferencesContext = createContext<UserPreferencesContextType>(
  {} as UserPreferencesContextType,
);

export function useUserPreferences(): UserPreferencesContextModel {
  return useContext(UserPreferencesContext);
}

export const UserPreferencesProvider = ({
  children,
}: UserPreferencesProviderProperties): ReactElement => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [userPreferences, setUserPreferences] = useRecoilState(userPreferencesState);

  const getPreferences = useCallback(() => {
    if (!project?.id) return;
    const preferences = userPreferences.find(
      (p) =>
        p.projectId === project.id && p.scopePathStringified === JSON.stringify(currentScopePath),
    );
    return preferences;
  }, [currentScopePath, project?.id, userPreferences]);

  const getGlobalPreferences = useCallback(() => {
    const preferences = userPreferences.find((p) => !p.projectId && !p.scopePathStringified);
    return preferences;
  }, [userPreferences]);

  const getUserPreferencesFromServer = useCallback(async () => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    const initialUserPreferences = await getUserPreferences({ uid: kaeplaUser.uid });
    const preferences = initialUserPreferences.find(
      (p) =>
        p.projectId === project.id && p.scopePathStringified === JSON.stringify(currentScopePath),
    );

    return preferences;
  }, [currentScopePath, kaeplaUser?.uid, project?.id]);

  const updateUserPreferencesInBackground = useCallback(
    (preferences: Partial<KaeplaUserPreferences>) => {
      if (!project?.id) return preferences;
      if (!kaeplaUser?.uid) return preferences;

      if (!preferences?.id) {
        preferences.id = getUserPreferencesId();
      }

      preferences.uid = kaeplaUser.uid;
      preferences.projectId = project.id;
      preferences.scopePath = currentScopePath;
      preferences.scopePathStringified = JSON.stringify(currentScopePath);

      void createOrUpdateUserPreferences({
        preferences,
      });

      return preferences;
    },
    [currentScopePath, kaeplaUser?.uid, project?.id],
  );

  const updateGlobalUserPreferencesInBackground = useCallback(
    (preferences: Partial<KaeplaUserPreferences>) => {
      if (!kaeplaUser?.uid) return preferences;

      if (!preferences?.id) {
        preferences.id = getUserPreferencesId();
      }

      preferences.uid = kaeplaUser.uid;

      // console.log('--------------------------------------');
      // console.log('preferences.id', preferences.id);
      // console.log('preferences', preferences);

      void createOrUpdateUserPreferences({
        preferences,
      });

      return preferences;
    },
    [kaeplaUser?.uid],
  );

  const setPreferences = useCallback(
    (incomingPreferences: KaeplaUserPreferences) => {
      if (!kaeplaUser?.uid) return;
      if (!project?.id) return;
      const existingPreferences = getPreferences() && {};
      const preferences = { ...existingPreferences, ...incomingPreferences };

      const updatedPreferences = updateUserPreferencesInBackground(preferences);

      const _userPreferences = [...userPreferences].map((p) => {
        if (
          p.projectId === project.id &&
          p.scopePathStringified === JSON.stringify(currentScopePath)
        ) {
          return { ...p, ...updatedPreferences };
        }
        return p;
      });

      setUserPreferences(_userPreferences);
    },
    [
      currentScopePath,
      getPreferences,
      kaeplaUser?.uid,
      project?.id,
      setUserPreferences,
      updateUserPreferencesInBackground,
      userPreferences,
    ],
  );

  const setGlobalPreferences = useCallback(
    (incomingPreferences: KaeplaUserPreferences) => {
      if (!kaeplaUser?.uid) return;
      const existingPreferences = getGlobalPreferences() && {};
      const preferences = { ...existingPreferences, ...incomingPreferences };

      const updatedPreferences = updateGlobalUserPreferencesInBackground(preferences);

      const _userPreferences = [...userPreferences].map((p) => {
        if (
          p.projectId === project.id &&
          p.scopePathStringified === JSON.stringify(currentScopePath)
        ) {
          return { ...p, ...updatedPreferences };
        }
        return p;
      });

      setUserPreferences(_userPreferences);
    },
    [
      currentScopePath,
      getGlobalPreferences,
      kaeplaUser?.uid,
      project?.id,
      setUserPreferences,
      updateGlobalUserPreferencesInBackground,
      userPreferences,
    ],
  );

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    const load = async () => {
      const initialUserPreferences = await getUserPreferences({ uid: kaeplaUser.uid });
      setUserPreferences(initialUserPreferences);
    };
    void load();
  }, [kaeplaUser?.uid, setUserPreferences]);

  const values: UserPreferencesContextModel = {
    userPreferences,
    getPreferences,
    getGlobalPreferences,
    setPreferences,
    setGlobalPreferences,
    getUserPreferencesFromServer,
  };

  return (
    <UserPreferencesContext.Provider value={values}>{children}</UserPreferencesContext.Provider>
  );
};
