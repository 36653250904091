import { getApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import isValidDomain from 'is-valid-domain';

import { KaeplaCustomerAuthDomain } from '../kaeplaTypes';

const getEmailDomain = (email: string) => {
  const atIndex = email.indexOf('@');
  if (atIndex !== -1) {
    return email.slice(atIndex + 1);
  }
  return '';
};

export const checkIfEmailIsHandledElseWhere = async (email: string, hostname: string) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const domain = getEmailDomain(email);

  if (!domain || !isValidDomain(domain)) {
    return false;
  }
  const fireStoreDocumentPath = `authDomains/${domain}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStoreDocumentPath);
  const documentSnap = await getDoc(firestoreDocumentReference);
  if (documentSnap.exists()) {
    const authDomain = documentSnap.data() as KaeplaCustomerAuthDomain;
    if (authDomain.customerDomains.includes(hostname)) {
      return false;
    }
    return authDomain;
  }
  return false;
};
