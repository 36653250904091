export const sequence1de = [
  3000,
  '',
  'lass mich nachdenken',
  1000,
  'lass mich nachdenken, lass mich nachdenken',
  3000,
  '',
  3000,
  'das könnte eine Weile dauern',
  5000,
  'das könnte eine Weile dauern, ich muss eine schlauere AI konsultieren',
  4000,
  '',
  3000,
  'vielleicht einen Schluck Kaffee?',
  3000,
  'vielleicht einen Schluck Kaffee? Tee?',
  6000,
  '',
  3000,
  'Ich kümmere mich darum, eiiiiiinen Moooooooment',
  2000,
  'Ich kümmere mich darum, fast fertig',
  1000,
  'Ich kümmere mich darum, versprochen',
  1000,
  'Ich kümmere mich darum, versprochen, echt',
  8000,
  '',
  3000,
  'nur noch ein paar Sekunden',
];

export const sequence2de = [
  2000,
  '',
  2000,
  'Moment bitte... gib mir einen Augenblick',
  4000,
  '',
  3000,
  'Ich muss einige Informationen sammeln',
  5000,
  '',
  2000,
  'Fast geschafft',
  3000,
  '',
  2000,
  'Nur noch ein bisschen länger',
  4000,
  '',
  3000,
  'Ich arbeite daran',
  3000,
  '',
  2000,
  'Warte mal, ich bin gleich fertig',
];

export const sequence3de = [
  2000,
  'einen Moment bitte, ich muss einige Informationen sammeln',
  5000,
  '',
  2000,
  'gleich geschafft, ich muss nur noch das Geheimrezept finden',
  3000,
  '',
  2000,
  'noch ein bisschen länger, ich verspreche, es wird sich lohnen',
  4000,
  '',
  3000,
  'Ich arbeite daran',
  '',
  3000,
  'Ich arbeite daran, wie ein Eichhörnchen auf einer Mission',
  3000,
  '',
  2000,
  'Ups, jetzt hab` ich mich selbst durcheinander gebracht. Aber keine Sorge, ich arbeite immer noch daran!',
];

export const sequence4de = [
  2000,
  '',
  'lass mich mal was nachsehen',
  3000,
  '',
  2000,
  'einen Moment bitte',
  4000,
  '',
  3000,
  'einen Moment bitte, ich muss ein paar Informationen zusammentragen',
  5000,
  '',
  2000,
  'fast da',
  '',
  2000,
  'fast da, fehlt nur noch die Formattierung',
  3000,
  '',
  2000,
  'ich bin gleich fertig',
  '',
  2000,
  'ich bin gleich fertig, ich glaub` ich hab`s',
];
