import numbro from 'numbro';

import { isItNumber } from '../lib/isItNumber';

export const widgetValueFormatter = (value: Record<string, unknown>, header: string) => {
  if (!value[header]) {
    value[header] = 'n/a';
    return value[header];
  }
  if (typeof value[header] === 'object') {
    value[header] = JSON.stringify(value[header]);
    return value[header];
  }
  if (header.toLowerCase().includes('year')) return value[header];
  if (header.toLowerCase().includes('count') && !header.toLowerCase().includes('discount')) {
    return value[header];
  }

  if (!isItNumber(value[header] as string)) {
    return value[header];
  }
  const floatified = (value[header] as number) * 1;
  const numberFormatted = numbro(floatified).format({
    thousandSeparated: true,
    mantissa: 2,
  });
  const averaged = numbro(floatified).format({
    average: true,
    mantissa: 2,
  });
  if (header.toLowerCase().includes('volume')) {
    value[header] = `${numberFormatted} (${averaged})`;
  } else {
    value[header] = ` € ${numberFormatted} (${averaged})`;
  }
  return value[header];
};
