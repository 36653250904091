import { Paper, Box, CssBaseline, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import Favicon from 'react-favicon';
import { useRecoilValue } from 'recoil';

import { BrandedLogin } from './features/BrandedLogin.js';
import { ConnectBrandedLogin } from './features/ConnectBrandedLogin.js';
import { ConnectFailed } from './features/ConnectFailed.js';
import { CustomBrandedLogin } from './features/CustomBrandedLogin.js';
import packageJson from '../../../../package.json';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState.js';
import { brandingState } from '../../../services/recoil/persistent/brandingState.js';
import { SWUpdater } from '../../features/ServiceWorkerUpdater.js';
import { isBeta } from '../../helpers/isBeta';

export const Login = () => {
  const app = useRecoilValue(applicationState);
  const branding = useRecoilValue(brandingState);

  return (
    <Box
      sx={{
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#EEEEEE',
      }}
    >
      <CssBaseline enableColorScheme />
      {branding?.appIconBase64 && <Favicon url={branding?.appIconBase64} />}
      <SWUpdater />
      <Paper id="login-box" sx={{ padding: 2, minWidth: 350, maxWidth: 400 }}>
        <Box data-testid="login-BrandedLoginPaper" sx={{ padding: 3 }}>
          {app.connecting && <ConnectBrandedLogin />}
          {!app.connecting && !branding?.appName && <ConnectFailed />}
          {!app.connecting && branding?.appName && !branding.authenticationConfiguration && (
            <BrandedLogin />
          )}
          {!app.connecting && branding?.appName && branding.authenticationConfiguration && (
            <CustomBrandedLogin />
          )}
        </Box>
        <Typography component="div" variant="caption" sx={{ textAlign: 'center', pt: 2 }}>
          {isBeta() && (
            <Box component="span" sx={{ color: red[500], fontSize: 14, mr: 1 }}>
              β
            </Box>
          )}
          {packageJson.version}
        </Typography>
      </Paper>
    </Box>
  );
};
