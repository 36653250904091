import { FirebaseError } from 'firebase/app';
import {
  Auth,
  UserCredential,
  User,
  signInWithEmailAndPassword,
  signOut,
  signInWithEmailLink,
  isSignInWithEmailLink,
  getMultiFactorResolver,
  MultiFactorResolver,
  AuthError,
  MultiFactorError,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithPopup,
  OAuthProvider,
  signInWithRedirect,
  unlink,
  linkWithRedirect,
  getRedirectResult,
  SAMLAuthProvider,
} from 'firebase/auth';
import { HttpsCallableResult } from 'firebase/functions';
import localforage from 'localforage';
import {
  ReactNode,
  useEffect,
  useState,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  ReactElement,
} from 'react';
import { useRecoilValue } from 'recoil';

import { getNamesFromEmail } from './Frontend/helpers/getNamesFromEmail.js';
import { authLog, mfaLog } from './Frontend/helpers/logger.js';
import { stringToColor } from './Frontend/helpers/stringToColor.js';
import { kaeplaAuth, watcherServiceKaepla } from './firebaseInit.js';
import { requestSignInLink } from './services/api/requestSignInLink.js';
import { createOrUpdateUser } from './services/firestore/createOrUpdateUser.js';
import { getUser } from './services/firestore/getUser.js';
import { KaeplaUser } from './services/kaeplaTypes/Application/KaeplaUser.js';
import { brandingState } from './services/recoil/persistent/brandingState.js';

export interface AuthProviderProperties {
  children?: ReactNode;
}

export interface AuthContextModel {
  auth: Auth;
  authError: FirebaseError | null;
  authProcedureError: string | null;
  authState: boolean | null;
  clearAuthError: () => void;
  clearAuthProcedureError: () => void;
  emailForSecondFactor: string;
  kaeplaUser: KaeplaUser | null;
  logOut: () => Promise<void>;
  multiFactorResolver: MultiFactorResolver | undefined;
  reAuthenticateWithPassword: (
    password: string,
    callback?: (isReauthenticated: boolean) => void,
  ) => Promise<void>;
  secondFactorRequired: boolean;
  setSecondFactorRequired: Dispatch<SetStateAction<boolean>>;
  sendEmailLink: (email: string) => Promise<void | HttpsCallableResult<unknown>>;
  setKaeplaUser: Dispatch<SetStateAction<KaeplaUser | null>>;
  setUser: Dispatch<SetStateAction<User | null>>;
  signIn: (email: string, password: string) => Promise<UserCredential | void>;
  signInWithLink: (checkEmail: string) => Promise<UserCredential | void>;
  signInWithOauthPopup: (provider: OAuthProvider) => Promise<void>;
  signInWithOauthRedirect: (provider: OAuthProvider) => Promise<void>;
  signInWithSamlSso: (providerId: string) => Promise<void>;
  getAuthRedirectResult: () => Promise<boolean | void>;
  addOAuthProvider: (providerId: string) => Promise<void>;
  removeAuthenticationProvider: (providerId: string) => Promise<User | undefined>;
  user: User | null;
}

export const AuthContext = createContext<AuthContextModel>({} as AuthContextModel);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

const getKaeplaUserFromFirebaseUser = (firestoreUser: KaeplaUser, firebaseUser: User) => {
  let firstName = '';
  let lastName = '';
  let displayName =
    firestoreUser?.displayName ?? firebaseUser?.displayName ?? firebaseUser?.email ?? 'n/a';

  let acronym = '';
  if (firebaseUser?.email) {
    const namesFromEmail = getNamesFromEmail(firebaseUser.email);
    firstName = namesFromEmail.firstName;
    lastName = namesFromEmail.lastName;
    if (!displayName) {
      displayName = namesFromEmail.displayName;
    }
    acronym = namesFromEmail.acronym;
  }

  if (!displayName) {
    displayName = `${firstName} ${lastName}`;
    acronym = `${displayName.split(' ')[0][0]}${displayName.split(' ')[1][0]}`;
  }

  if (!displayName) {
    const nameParts = displayName.split(' ');
    if (nameParts.length === 2) {
      acronym = `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}`;
    }
  }

  const _kaeplaUser: KaeplaUser = {
    ...firestoreUser,
    uid: firebaseUser.uid,
    displayName,
    acronym,
    color: stringToColor(`Ä${displayName}`),
    email: firebaseUser.email ?? 'n/a',
  };

  return _kaeplaUser;
};

export const AuthReactProvider = ({ children }: AuthProviderProperties): ReactElement => {
  const branding = useRecoilValue(brandingState);
  const [user, setUser] = useState<User | null>(null);
  const [kaeplaUser, setKaeplaUser] = useState<KaeplaUser | null>(null);
  const [authState, setAuthState] = useState<boolean | null>(null);
  const [authError, setAuthError] = useState<FirebaseError | null>(null);
  const [authProcedureError, setAuthProcedureError] = useState<string | null>(null);
  const [secondFactorRequired, setSecondFactorRequired] = useState<boolean>(false);
  const [emailForSecondFactor, setEmailForSecondFactor] = useState<string>('');
  const [multiFactorResolver, setMultiFactorResolver] = useState<MultiFactorResolver>();

  const clearAuthError = () => {
    setAuthError(null);
  };

  const clearAuthProcedureError = () => {
    setAuthProcedureError(null);
  };

  const isEmailHandled = useCallback(
    (email: string | undefined | null) => {
      if (email === undefined) return false;
      if (email === null) return false;
      if (!branding?.authenticationConfiguration?.configuration.handledDomains) return false;
      return branding?.authenticationConfiguration?.configuration.handledDomains?.some(
        (domain) => email.endsWith(domain) && /^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(email),
      );
    },
    [branding],
  );

  const signInWithOauthPopup = async (provider: OAuthProvider) => {
    setAuthError(null);
    return signInWithPopup(kaeplaAuth, provider)
      .then((result) => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        // const credential = OAuthProvider.credentialFromResult(result);
        // const accessToken = credential.accessToken;
        // const idToken = credential.idToken;
        if (isEmailHandled(result.user?.email)) {
          setAuthProcedureError('Single Sign On required');
          void signOut(kaeplaAuth);
          return;
        }
        authLog.log('OAuth with popup login success', !!result);
      })
      .catch((error: AuthError) => {
        setAuthError(error);
      });
  };

  const signInWithOauthRedirect = async (provider: OAuthProvider) => {
    setAuthError(null);
    return signInWithRedirect(kaeplaAuth, provider)
      .then((result) => {
        if (isEmailHandled(kaeplaAuth.currentUser?.email)) {
          setAuthProcedureError('Single Sign On required');
          void signOut(kaeplaAuth);
          return;
        }
        authLog.log('OAuth with redirect login success', !!result);
      })
      .catch((error: AuthError) => {
        setAuthError(error);
      });
  };

  const signInWithSamlSso = async (providerId: string) => {
    setAuthError(null);
    const provider = new SAMLAuthProvider(providerId);
    authLog.log('SAML SSO login with provider:', providerId);
    return signInWithRedirect(kaeplaAuth, provider)
      .then((result) => {
        authLog.log('SAML-SSO auth with redirect login success', !!result);
      })
      .catch((error: AuthError) => {
        setAuthError(error);
      });
  };

  const removeAuthenticationProvider = async (providerId: string) => {
    if (!user) return;
    return unlink(user, providerId).then(async (authUser) => {
      setAuthState(false);
      authLog.log('provider removed, reload user');
      // TODO: maybe find a better way to reload the user
      setUser(authUser);
      await user.reload();
      setAuthState(true);
      return authUser;
    });
  };

  const addOAuthProvider = async (providerId: string) => {
    if (!user) return;
    const provider = new OAuthProvider(providerId);
    return linkWithRedirect(user, provider);
  };

  const getAuthRedirectResult = useCallback(async () => {
    return getRedirectResult(kaeplaAuth)
      .then((result) => {
        if (!result) {
          authLog.log('No result from oauth redirect');
          return false;
        }
        authLog.log('oauth providerId', result.providerId);
        authLog.log('oauth operation type', result.operationType);
        authLog.log('oauth redirect user', result.user);

        if (result?.providerId?.startsWith('saml') && result.user?.providerData) {
          result.user.providerData.forEach((userInfo) => {
            if (userInfo.providerId === result.providerId) return;
            authLog.log('SAML SSO provider activated, unlinking all other providers');
            authLog.log('unlink authentication provider', userInfo.providerId);
            void unlink(result.user, userInfo.providerId);
          });
        }

        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        if (!credential) {
          authLog.log('No credential from redirect error:', !credential);
          setAuthProcedureError('No credential from redirect');
          return false;
        }

        if (isEmailHandled(kaeplaAuth.currentUser?.email)) {
          authLog.log('email is handled error:', kaeplaAuth.currentUser?.email);
          setAuthProcedureError('Single Sign On required');
          void signOut(kaeplaAuth);
          return;
        }

        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        authLog.log('idToken Result', idToken);
        authLog.log('accessToken Result', accessToken);
        setAuthError(null);
        return true;
      })
      .catch((error: AuthError) => {
        authLog.log('redirectResult error:', error);
        setAuthError(error);
      });
  }, [isEmailHandled]);

  const signIn = async (email: string, password: string): Promise<UserCredential | void> => {
    setAuthError(null);
    return signInWithEmailAndPassword(kaeplaAuth, email, password)
      .then(() => {
        if (isEmailHandled(email)) {
          setAuthProcedureError('Single Sign On required');
          void signOut(kaeplaAuth);
          return;
        }
      })
      .catch((error: AuthError) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          setSecondFactorRequired(true);
          setEmailForSecondFactor(email);
          const resolver = getMultiFactorResolver(kaeplaAuth, error as MultiFactorError);
          setMultiFactorResolver(resolver);
          return;
        }
        setAuthError(error);
      });
  };

  const reAuthenticateWithPassword = async (
    password: string,
    callback?: (isAuthenticated: boolean) => void,
  ) => {
    if (!user?.email) return;
    const credential = EmailAuthProvider.credential(user.email, password);
    mfaLog.log('re-authenticate with password');
    await reauthenticateWithCredential(user, credential)
      .then(() => {
        mfaLog.log('no need for a second second factor');
        if (callback) callback(true);
      })
      .catch((error: AuthError) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          mfaLog.log('we need second factor', error.code);
          setSecondFactorRequired(true);
          const resolver = getMultiFactorResolver(kaeplaAuth, error as MultiFactorError);
          setMultiFactorResolver(resolver);
          return;
        }
        mfaLog.log('we have a problem', error.code);
        if (callback) callback(false);
      });

    mfaLog.log('reauthenticateWithCredential');
    await user.reload();
  };

  const signInWithLink = async (checkEmail: string) => {
    setAuthError(null);
    if (isSignInWithEmailLink(kaeplaAuth, window.location.href)) {
      if (isEmailHandled(checkEmail)) {
        setAuthProcedureError('Single Sign On required');
        void signOut(kaeplaAuth);
        return;
      }
      return signInWithEmailLink(kaeplaAuth, checkEmail, window.location.href).catch(
        (error: FirebaseError) => {
          setAuthError(error);
        },
      );
    }
    return;
  };

  const sendEmailLink = async (email: string) => {
    setAuthError(null);
    if (isEmailHandled(email)) {
      authLog.log('sendEmailLink - Email is handled!');
      setAuthProcedureError('Single Sign On required');
      return;
    }
    return requestSignInLink({ email, hostname: window.location.hostname }).catch(
      (error: FirebaseError) => {
        setAuthError(error);
      },
    );
  };

  const logOut = async () => {
    watcherServiceKaepla.get().unsubscribeAll();
    await new Promise((resolve) => setTimeout(resolve, 500));
    await localforage.clear();
    authLog.log('clear localforage');
    await signOut(kaeplaAuth);
    authLog.log('sign out from firebase');
    setAuthState(false);
  };

  // This is if we log in via emailLink directly on kaepla
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const unsubscribe = kaeplaAuth.onAuthStateChanged((firebaseUser) => {
      const loadUser = async () => {
        await getAuthRedirectResult();
        if (firebaseUser?.email && firebaseUser?.uid) {
          const idTokenResult = await firebaseUser.getIdTokenResult();
          authLog.log('idToken Result', idTokenResult);
          if (idTokenResult?.claims?.exp) {
            const expirationTime = Number.parseInt(idTokenResult.claims.exp, 10) * 1000; // ms
            const millisecondsUntilExpiration = Date.now() - expirationTime;
            authLog.log('idToken expirationTime', expirationTime);
            authLog.log('idToken secs left', millisecondsUntilExpiration / 1000);
            authLog.log('idToken mins left', millisecondsUntilExpiration / 1000 / 60);
          }
          // authLog.log('----> firebaseUser', firebaseUser);

          const userFromFirestore = await getUser({ uid: firebaseUser.uid });
          const _kaeplaUser = getKaeplaUserFromFirebaseUser(userFromFirestore, firebaseUser);
          void createOrUpdateUser({ user: _kaeplaUser });
          setKaeplaUser(_kaeplaUser);
          setAuthState(true);
        } else {
          watcherServiceKaepla.get().unsubscribeAll();
          await localforage.clear();
          setAuthState(false);
        }
        setUser(firebaseUser);
      };
      void loadUser();
    });
    return unsubscribe;
  }, [getAuthRedirectResult]);

  const values: AuthContextModel = {
    auth: kaeplaAuth,
    authError,
    authProcedureError,
    authState,
    clearAuthError,
    clearAuthProcedureError,
    emailForSecondFactor,
    kaeplaUser,
    logOut,
    multiFactorResolver,
    reAuthenticateWithPassword,
    secondFactorRequired,
    setSecondFactorRequired,
    sendEmailLink,
    setKaeplaUser,
    setUser,
    signIn,
    signInWithLink,
    signInWithOauthPopup,
    signInWithOauthRedirect,
    signInWithSamlSso,
    getAuthRedirectResult,
    addOAuthProvider,
    removeAuthenticationProvider,
    user,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
