import { FirestoreWatcherHandler } from '@atrigam/atrigam-service-firebase-watcher';
import { getApp, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

import { logger } from './Frontend/helpers/logger.js';
import { IS_CI, IS_DEV } from './mode.js';
import { kaeplaFirebaseConfig } from '../kaeplaFirebase.config.js';

// Initialize Firebase
const firebaseConfig = kaeplaFirebaseConfig();
export const firebaseApp = initializeApp(firebaseConfig, 'kaepla');

// initialize watcher
/*
allowedDuplicatePaths: ['projectAssignments', 'resellerAssignments', 'customerAssignments']

because we have 
1) a listener for each per logged in user and
2) for each project, reseller and customer team

*/
export const watcherServiceKaepla = FirestoreWatcherHandler();
watcherServiceKaepla.initialize({
  app: firebaseApp,
  allowedDuplicatePaths: [
    'projectAssignments',
    'resellerAssignments',
    'customerAssignments',
    'importLogs',
    'aiThreads',
    'aiRuns',
    'aiMessages',
    'aiToolCalls',
  ],
  isDev: IS_DEV,
  logError: ({ error, context }) => {
    // TODO: skip if we are logging out
    logger.log(error, context);
  },
});

export const kaeplaAuth = getAuth(firebaseApp);

if (IS_CI) {
  logger.log('CI environment detected!');
  // on a local emulator and the ci environment
  const localHosts = [
    'localhost',
    '127.0.0.1',
    '10.0.0.18',
    'customer-simulations.atrigam.local',
    'customer-planning.atrigam.local',
    'simulations.atrigam.local',
    'simulations.xotigo.local',
  ];

  if (localHosts.includes(window.location.hostname)) {
    connectAuthEmulator(kaeplaAuth, 'http://localhost:9099');
    initializeFirestore(getApp('kaepla'), { experimentalForceLongPolling: true });
    const firestoreDatabase = getFirestore(getApp('kaepla'));
    connectFirestoreEmulator(firestoreDatabase, 'localhost', 8080);
    const functions = getFunctions(getApp('kaepla'), 'europe-west3');
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }
} else {
  logger.log('PRODUCTION environment detected!');
  initializeFirestore(getApp('kaepla'), { experimentalForceLongPolling: true });
}
