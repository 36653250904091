import { detect as detectBrowser } from 'detect-browser';
import { getApp } from 'firebase/app';
import { collection, doc, DocumentData, getFirestore, setDoc, Timestamp } from 'firebase/firestore';

import { getUserByEmail } from './getUserByEmail.js';
import { mapUndefinedToNullValue } from './mapUndefinedToNullValue.js';
import { logger } from '../../Frontend/helpers/logger';
import { KaeplaCustomer } from '../kaeplaTypes/Application/KaeplaCustomer.js';
import { KaeplaEvent, KaeplaEventInfo } from '../kaeplaTypes/Application/KaeplaEvent.js';
import { KaeplaEventLogLevel } from '../kaeplaTypes/Application/KaeplaEventLogLevel.js';
import { KaeplaEventType } from '../kaeplaTypes/Application/KaeplaEventType.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';
import { KaeplaReseller } from '../kaeplaTypes/Application/KaeplaReseller.js';
import { FirestoreTimestamp } from '../kaeplaTypes/FirestoreTimeStamp.js';

interface EventOptions extends Partial<KaeplaEvent> {
  project?: KaeplaProject;
  customer?: KaeplaCustomer;
  reseller?: KaeplaReseller;
}

export const createEvent = async (properties: EventOptions) => {
  logger.log('LOGGING EVENT', properties);
  const eventProperties = { ...properties };

  delete eventProperties.reseller;
  delete eventProperties.customer;
  delete eventProperties.project;

  if (!eventProperties.eventInfo) {
    eventProperties.eventInfo = {} as KaeplaEventInfo;
  }
  const browserInfo = detectBrowser() as unknown as Record<string, unknown>;
  const { availHeight, availWidth, colorDepth, width, height, pixelDepth } = window.screen;
  if (browserInfo) {
    eventProperties.eventInfo.browserInfo = browserInfo;
    eventProperties.eventInfo.screen = {
      availHeight,
      availWidth,
      colorDepth,
      width,
      height,
      pixelDepth,
    };
  }

  if (properties.project) {
    eventProperties.projectId = properties.project.id;
    eventProperties.customerId = properties.project.customerId;
    eventProperties.resellerId = properties.project.resellerId;
  }
  if (eventProperties.projectId && !properties.scopePath) {
    eventProperties.scopePath = [];
    eventProperties.scopePathStringified = '[]';
  }
  if (properties.scopePath && !properties.scopePathStringified) {
    eventProperties.scopePathStringified = JSON.stringify(properties.scopePath);
  }
  if (properties.customer) {
    eventProperties.customerId = properties.customer.id;
    eventProperties.resellerId = properties.customer.resellerId;
  }
  if (properties.reseller) {
    eventProperties.resellerId = properties.reseller.id;
  }
  if (properties?.eventInfo?.affectedUserEmail && !properties.eventInfo?.affectedUserUid) {
    const affectedUser = await getUserByEmail({ email: properties.eventInfo.affectedUserEmail });
    if (properties?.eventInfo && affectedUser) {
      eventProperties.eventInfo.affectedUserUid = affectedUser.uid;
    }
  }
  if (!properties.accessDomain) {
    eventProperties.accessDomain = window.location.hostname;
  }

  if (properties?.functionGroup) {
    eventProperties.functionGroup = properties.functionGroup;
  }

  if (properties?.operation) {
    eventProperties.operation = properties.operation;
  }

  if (properties?.simulationId) {
    eventProperties.simulationId = properties.simulationId;
  }

  let logLevel = 0 as KaeplaEventLogLevel;
  if (properties?.logLevel) {
    logLevel = properties.logLevel;
  }

  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `events`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const firestoreDocumentReference = doc(firestoreCollectionReference);
  const eventType: KaeplaEventType = (properties.eventType ?? 'Event') as KaeplaEventType; // TODO: we want the enum here
  const event: KaeplaEvent = {
    ...(eventProperties as Record<string, unknown>),
    dateTime: Timestamp.now() as FirestoreTimestamp,
    logLevel,
    eventType,
  };

  await setDoc(firestoreDocumentReference, mapUndefinedToNullValue(event) as DocumentData);

  return event;
};
