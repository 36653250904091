import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthReactProvider.js';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener.js';
import { getProjectsFromProjectIds } from '../../services/firestore/getProjectFromProjectIds.js';
import { KaeplaProject } from '../../services/kaeplaTypes/Application/KaeplaProject.js';
import { KaeplaProjectAssignment } from '../../services/kaeplaTypes/Application/KaeplaProjectAssignment.js';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState.js';
import { knownProjectsState } from '../../services/recoil/nonpersistent/knownProjectsState.js';
import { projectsOverviewState } from '../../services/recoil/nonpersistent/projectsOverviewState.js';
import { selectedCustomerState } from '../../services/recoil/nonpersistent/selectedCustomer.js';
import { projectAssignmentsState } from '../../services/recoil/nonpersistent/userAssignmentState.js';
import { userRootPathsState } from '../../services/recoil/nonpersistent/userRootPathsState.js';
import { getUserRootPaths } from '../helpers/getUserRootPaths.js';
import { logger } from '../helpers/logger.js';

export const ProjectAssignmentsListenerKaeplaAdmins = () => {
  const { kaeplaUser } = useAuth();
  const setApp = useSetRecoilState(applicationState);
  const setKnownProjects = useSetRecoilState(knownProjectsState);
  const setProjectsOverview = useSetRecoilState(projectsOverviewState);
  const setProjectAssignments = useSetRecoilState(projectAssignmentsState);
  const setUserRootPaths = useSetRecoilState(userRootPathsState);
  const selectedCustomer = useRecoilValue(selectedCustomerState);

  // projectsListener -> for admins
  useEffect(() => {
    if (!kaeplaUser?.uid) return; // user is an admin or not authenticated yet
    // no additional checks here because:
    // we know that app.connecting isn't true any more,
    // as we set kaeplaAssignment after connecting
    // and this component only loads if we have a kaeplaAssignment

    logger.log('\nuseEffect ProjectAssignmentsListenerKaeplaAdmins');

    const fireStorePathProjects = `projects`;
    const unsubscribeProjectsListener = addFirestoreCollectionListener({
      fireStorePath: fireStorePathProjects,
      callback: (_projects) => {
        const projectIds = (_projects as KaeplaProject[]).map((p) => p.id);
        logger.log('ProjectListenerKaeplaAdmins fires:', projectIds);
        const loadProjects = async () => {
          let projectsOrdered = await getProjectsFromProjectIds({ projectIds }); // TODO: can we cache those?
          if (!projectsOrdered) {
            setKnownProjects([]);
            setProjectsOverview([]);
            setApp((previousApp) => ({ ...previousApp, projectsLoaded: true }));
            return;
          }

          if (selectedCustomer) {
            projectsOrdered = [...projectsOrdered].filter(
              (project) => project.customerId === selectedCustomer.id,
            );
          }

          setUserRootPaths((existingRootPaths) => {
            const extendedRootPaths = { ...existingRootPaths };
            if (!projectsOrdered) return extendedRootPaths;
            projectsOrdered.forEach((project) => {
              if (!existingRootPaths[project.id]) {
                extendedRootPaths[project.id] = '[]';
              }
            });
            return extendedRootPaths;
          });
          logger.log('ProjectListenerKaeplaAdmins setKnownProjects', projectsOrdered);
          setKnownProjects(projectsOrdered);
          setProjectsOverview(projectsOrdered);
          setApp((previousApp) => ({ ...previousApp, projectsLoaded: true }));
        };
        void loadProjects();
      },
    });

    const fireStorePathProjectAssignments = `projectAssignments`;
    const queryWhere: WhereClause[] = [];
    // filter by uid for non kaepla admins
    queryWhere.push(
      {
        fieldPath: 'uid',
        opStr: '==',
        value: kaeplaUser.uid,
      },
      {
        fieldPath: 'complete',
        opStr: '==',
        value: true,
      },
    );

    const unsubscribeProjectAssignmentListener = addFirestoreCollectionListener({
      fireStorePath: fireStorePathProjectAssignments,
      queryWhere,
      callback: (projectAssignments) => {
        logger.log('ProjectAssignmentsListenerKaeplaAdmins fires:', projectAssignments);
        setProjectAssignments(projectAssignments as KaeplaProjectAssignment[]);
        const userRootPaths = getUserRootPaths(projectAssignments as KaeplaProjectAssignment[]);
        setUserRootPaths(userRootPaths);
      },
    });

    return () => {
      logger.log('ProjectListenerKaeplaAdmins is unmounted!');
      unsubscribeProjectsListener();
      logger.log('ProjectAssignmentsListenerKaeplaAdmins is unmounted!');
      unsubscribeProjectAssignmentListener();
    };
  }, [
    kaeplaUser?.uid,
    setApp,
    setKnownProjects,
    setProjectAssignments,
    setUserRootPaths,
    selectedCustomer,
    setProjectsOverview,
  ]);

  return null;
};
