import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect.js';

export const currentScopePathState = atom<string[] | undefined>({
  key: 'currentScopePath',
  default: undefined,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
