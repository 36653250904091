import { atom } from 'recoil';

import { KaeplaCustomer } from '../../kaeplaTypes/Application/KaeplaCustomer.js';

const defaultSelectedCustomerState: KaeplaCustomer | undefined = undefined;

export const selectedCustomerState = atom<KaeplaCustomer | undefined>({
  key: 'selectedCustomer',
  default: defaultSelectedCustomerState,
});
