import { getApp } from 'firebase/app';
import { getFirestore, collection, query, getDocs, where } from 'firebase/firestore';

import { KaeplaUserPreferences } from '../kaeplaTypes/Application/KaeplaUserPreferences.js';

interface Options {
  uid: string;
}

export const getUserPreferences = async ({ uid }: Options) => {
  const data: KaeplaUserPreferences[] = [];
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `userPreferences`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const firestoreQuery = query(firestoreCollection, where('uid', '==', uid));
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document_) => {
    const userPreferences = document_.data();
    userPreferences.id = document_.id;
    data.push(userPreferences as KaeplaUserPreferences);
  });
  return data;
};
