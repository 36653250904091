import MonitoringNotAllowedIcon from '@mui/icons-material/DoNotDisturbOnTotalSilence';
import MonitoringAllowedIcon from '@mui/icons-material/RadioButtonChecked';
import { IconButton } from '@mui/material';

import { ThreadMessage } from '../../../services/kaeplaTypes';
import { logger } from '../../helpers/logger';

export const AiMonitoringToggle = ({ aiMessage }: { aiMessage: ThreadMessage }) => {
  return (
    <IconButton
      data-testid="toggle-ai-monitoring"
      edge="end"
      size="small"
      onClick={(event) => {
        event.stopPropagation();
        logger.log('toggle');
      }}
    >
      {aiMessage.monitoringAllowed ? (
        <MonitoringAllowedIcon fontSize="small" sx={{ fontSize: 16 }} />
      ) : (
        <MonitoringNotAllowedIcon fontSize="small" sx={{ fontSize: 16, opacity: 0.6 }} />
      )}
    </IconButton>
  );
};
