import SearchIcon from '@mui/icons-material/SearchOutlined';
import { Box, Button, Grid, IconButton, InputBase, Paper, Toolbar } from '@mui/material';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider';
import { sendAiMessage } from '../../../services/api/sendAiMessage';
import { createEvent } from '../../../services/firestore/createEvent';
import {
  KaeplaEventType,
  KaeplaFunctionGroup,
  KaeplaDataOperation,
} from '../../../services/kaeplaTypes';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';

interface Options {
  processing: boolean;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setInitializingThread: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SendAiMessage = ({ setMessage, processing, setInitializingThread }: Options) => {
  const { kaeplaUser } = useAuth();
  const [currentMessage, setCurrentMessage] = useState('');
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);

  const logAskQuestion = useMemo(() => {
    if (!kaeplaUser?.uid) return {};
    return {
      uid: kaeplaUser.uid,
      eventType: KaeplaEventType.AI_ASK_QUESTION,
      functionGroup: KaeplaFunctionGroup.AI,
      operation: KaeplaDataOperation.READ,
      project,
    };
  }, [kaeplaUser?.uid, project]);

  const submitMessage = (restartThread?: boolean) => {
    if (!kaeplaUser?.uid) return;
    // if (currentMessage === '') return;
    setMessage(currentMessage);
    setCurrentMessage('');
    const parameters = {
      scopePathStringified: JSON.stringify(currentScopePath),
      projectId: project.id,
      message: currentMessage || undefined,
      restartThread: !!restartThread,
    };
    if (restartThread) {
      setInitializingThread(true);
      delete parameters.message;
    }
    sendAiMessage({
      callBack: () => {
        void createEvent(logAskQuestion);
      },
      params: parameters,
      uid: kaeplaUser.uid,
    });
  };
  return (
    <Grid container sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              submitMessage(true);
            }}
          >
            Clear
          </Button>
          <Box sx={{ ml: 1, flexGrow: 1 }}>
            <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                fullWidth
                value={currentMessage}
                placeholder="Ask a question"
                name="AiMessage"
                onChange={(_event) => {
                  setCurrentMessage(_event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    submitMessage();
                  }
                }}
                disabled={processing}
              />
              <IconButton
                type="button"
                sx={{ p: '2px' }}
                aria-label="ask"
                disabled={processing}
                onClick={() => {
                  submitMessage();
                }}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
        </Toolbar>
      </Grid>
    </Grid>
  );
};
