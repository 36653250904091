import { KaeplaAuthenticationProvider } from '../../../../../services/kaeplaTypes/Application/KaeplaAuthenticationProvider.js';

export const oAuth2Providers: KaeplaAuthenticationProvider[] = [
  {
    label: 'Microsoft',
    providerId: 'microsoft.com',
    type: 'oAuth2',
    active: true,
  },
  {
    label: 'Google',
    providerId: 'google.com',
    type: 'oAuth2',
    active: true,
  },
  {
    label: 'Apple',
    providerId: 'apple.com',
    type: 'oAuth2',
    active: false,
  },
  {
    label: 'Github',
    providerId: 'github.com',
    type: 'oAuth2',
    active: false,
  },
];
