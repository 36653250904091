import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

import { KaeplaUser } from '../kaeplaTypes/Application/KaeplaUser.js';

interface Options {
  uid: string;
}

export const getUser = async ({ uid }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `users/${uid}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  const document = await getDoc(firestoreDocumentReference);
  return document.data() as KaeplaUser;
};
