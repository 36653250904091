import { FirestoreTimestamp } from '../kaeplaTypes/FirestoreTimeStamp.js';

export const isFirestoreTimestamp = <T extends FirestoreTimestamp>(
  timestamp: unknown,
): timestamp is T => {
  if (typeof timestamp !== 'object' || timestamp === null || timestamp === undefined) {
    return false;
  }

  const fTimestamp = timestamp as T;

  return (
    fTimestamp.seconds !== undefined &&
    fTimestamp.nanoseconds !== undefined &&
    typeof fTimestamp.toDate === 'function' &&
    typeof fTimestamp.toMillis === 'function' &&
    typeof fTimestamp.valueOf === 'function'
  );
};
