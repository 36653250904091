import { atom } from 'recoil';

import { KaeplaUserPreferences } from '../../kaeplaTypes/Application/KaeplaUserPreferences';

const defaultUserPreferencesState: KaeplaUserPreferences[] = [];

export const userPreferencesState = atom<KaeplaUserPreferences[]>({
  key: 'userPreferences',
  default: defaultUserPreferencesState,
});
