import { atom } from 'recoil';

import { KaeplaProjectAssignment } from '../../kaeplaTypes/Application/KaeplaProjectAssignment.js';

const defaultProjectAssignmentState: KaeplaProjectAssignment[] | undefined = [];

export const projectAssignmentsState = atom({
  key: 'projectAssignments',
  default: defaultProjectAssignmentState,
});
