import CloseIcon from '@mui/icons-material/Close';
import { Grid, TextField, Button, Typography, Alert, IconButton } from '@mui/material';
import Image from 'mui-image';
import { useState } from 'react';

import { useAuth } from '../../../../AuthReactProvider.js';
import { KaeplaBranding } from '../../../../services/kaeplaTypes/Application/KaeplaBranding.js';

interface Options {
  branding: KaeplaBranding;
  setCheckEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const BrandedCheckLoginLinkEmail = ({ branding, setCheckEmail }: Options) => {
  const { authError, clearAuthError } = useAuth();
  const [email, setEmail] = useState<string>('');

  return (
    <Grid
      container
      spacing={3}
      // direction="column"
      // justifyContent="center"
      alignItems="center"
    >
      {branding?.appLogoUrl && (
        <Grid item xs={12} container justifyContent="center">
          <Image width={200} alt={branding?.appName} src={branding?.appLogoUrl} />
        </Grid>
      )}
      {branding?.appName && !branding.appLogoUrl && (
        <Grid item xs={12}>
          <Typography sx={{ color: '#000' }} variant="h2">
            {branding.appName}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Alert variant="outlined" severity="info">
          Please enter the email address for which you received the login link.
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Email"
          size="small"
          fullWidth
          sx={{ minWidth: 250 }}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoFocus
        />
      </Grid>
      {authError && (
        <Grid item xs={12}>
          <Alert
            variant="outlined"
            severity="error"
            data-testid="login-BrandedLoginErrorAlert"
            action={
              <IconButton
                aria-label="close"
                data-testid="login-BrandedLoginErrorAlertClose"
                color="inherit"
                size="small"
                onClick={() => {
                  setEmail('');
                  clearAuthError();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {authError.code}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => {
            setCheckEmail(email);
          }}
        >
          Login
        </Button>
      </Grid>
    </Grid>
  );
};
