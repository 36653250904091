import { KaeplaProjectAssignment } from '../../services/kaeplaTypes/Application/KaeplaProjectAssignment.js';

export const getUserRootPaths = (projectAssignments: KaeplaProjectAssignment[]) => {
  const _userRootPaths: Record<string, string> = {};
  projectAssignments.forEach((assignment) => {
    if (!_userRootPaths[assignment.projectId]) {
      _userRootPaths[assignment.projectId] = assignment.scopePathStringified;
    }
    // we overwrite here the root path for a project if the current assignment has a shorter path
    if (assignment.scopePathStringified.length < _userRootPaths[assignment.projectId].length) {
      _userRootPaths[assignment.projectId] = assignment.scopePathStringified;
    }
  });

  return _userRootPaths;
};
