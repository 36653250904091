import { getApp } from 'firebase/app';
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
  orderBy,
} from 'firebase/firestore';

import { logger } from '../../Frontend/helpers/logger';
import { ThreadType } from '../kaeplaTypes';

interface Options {
  uid: string;
  projectId: string;
  type: ThreadType;
}

export const getThreadIDForProjectAndUser = async ({ uid, projectId, type }: Options) => {
  const fireStorePath = `aiThreads`;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);

  const firestoreQuery = query(
    firestoreCollection,
    where('uid', '==', uid),
    where('projectId', '==', projectId),
    where('type', '==', type),
    limit(1),
    orderBy('createdAt', 'desc'),
  );
  const snapshot = await getDocs(firestoreQuery).catch((error) => {
    logger.error(`Error getting thread ID: ${error}`);
  });
  if (!snapshot || snapshot.docs.length === 0) return;
  return snapshot.docs[0].data().id as unknown as string;
};
