import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { MenuItem, ListItemIcon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createContext, useContext } from 'react';

export const ColorModeContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleColorMode: () => {},
});

export const ColorModeToggle = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  return (
    <MenuItem onClick={colorMode.toggleColorMode}>
      <ListItemIcon>
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </ListItemIcon>
      Mode
    </MenuItem>
  );
};
