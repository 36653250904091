import { getApp } from 'firebase/app';
import { collection, doc, getFirestore } from 'firebase/firestore';

export const getUserPreferencesId = () => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStoreCollectionPath = `userPreferences`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStoreCollectionPath);
  const firestoreDocumentReference = doc(firestoreCollectionReference);
  return firestoreDocumentReference.id;
};
