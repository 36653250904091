import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthReactProvider.js';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener.js';
import { getResellers } from '../../services/firestore/getResellers.js';
import { KaeplaDomainType } from '../../services/kaeplaTypes/Application/KaeplaDomainType.js';
import { KaeplaResellerAssignment } from '../../services/kaeplaTypes/Application/KaeplaResellerAssignment.js';
import { knownResellersState } from '../../services/recoil/nonpersistent/knownResellersState.js';
import { resellerAssignmentsState } from '../../services/recoil/nonpersistent/resellerAssignmentState.js';
import { brandingState } from '../../services/recoil/persistent/brandingState.js';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState.js';
import { UnhandledCaseError } from '../helpers/UnhandledCaseError.js';
import { logger } from '../helpers/logger.js';

export const ResellerAssignmentsListener = () => {
  const { kaeplaUser } = useAuth();
  const setKnownResellers = useSetRecoilState(knownResellersState);
  const setResellerAssignments = useSetRecoilState(resellerAssignmentsState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const branding = useRecoilValue(brandingState);

  useEffect(() => {
    if (!kaeplaUser?.uid) return; // user isn't authenticated yet
    if (!branding?.realmType || !branding?.appRealmId || !branding.providerId) return; // we always need a branding
    const fireStorePath = `resellerAssignments`;
    const queryWhere: WhereClause[] = [];
    if (!kaeplaAssignment) {
      queryWhere.push(
        {
          fieldPath: 'uid',
          opStr: '==',
          value: kaeplaUser.uid,
        },
        {
          fieldPath: 'complete',
          opStr: '==',
          value: true,
        },
      );

      switch (branding.realmType) {
        case KaeplaDomainType.customer: {
          // TODO: how can we use KaeplaDomainType.customer?
          // customer domain lock through branding
          queryWhere.push({
            fieldPath: 'resellerId',
            opStr: '==',
            value: branding.providerId,
          });

          break;
        }

        case KaeplaDomainType.reseller: {
          // customer domain lock through branding
          queryWhere.push({
            fieldPath: 'resellerId',
            opStr: '==',
            value: branding.appRealmId,
          });

          break;
        }

        default: {
          throw new UnhandledCaseError(branding.realmType);
        }
      }
    }

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (_resellerAssignments) => {
        const load = async () => {
          const resellerAssignments = _resellerAssignments as KaeplaResellerAssignment[];
          logger.log('ResellerAssignmentsListener fires:', resellerAssignments);
          setResellerAssignments(resellerAssignments);
          if (resellerAssignments.length === 0) {
            setKnownResellers([]);
            return;
          }

          const resellerIds = resellerAssignments.map((c) => c.resellerId);
          const uniqueResellerIds = resellerIds.filter((v, index, a) => a.indexOf(v) === index);
          const _knownResellers = await getResellers({ resellerIds: uniqueResellerIds });
          setKnownResellers(_knownResellers);
        };
        void load();
      },
    });
    return () => {
      unsubscribe();
    };
  }, [
    branding?.appRealmId,
    branding?.providerId,
    branding?.realmType,
    kaeplaAssignment,
    kaeplaUser?.uid,
    setKnownResellers,
    setResellerAssignments,
  ]);

  return null;
};
