import { Alert, AlertTitle, Button, Snackbar } from '@mui/material';
import localForage from 'localforage';
import { useRegisterSW } from 'virtual:pwa-register/react';

const ServiceWorkerUpdater = () => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(_, registration) {
      registration &&
        setInterval(() => {
          // console.log('Checking for sw update');
          void registration.update();
        }, 20_000 /* checks every 20s if there is an update available */);
    },
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log('SW registration error', error);
    },
  });

  return needRefresh ? (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open>
      <Alert
        severity="info"
        action={
          <Button
            onClick={() => {
              void localForage.clear(); // clear out recoil-persist
              void updateServiceWorker(true);
            }}
          >
            Update
          </Button>
        }
      >
        <AlertTitle>New version available</AlertTitle>
        It's recommended to update right away.
      </Alert>
    </Snackbar>
  ) : null;
};

export const SWUpdater = ServiceWorkerUpdater;
