import { useRecoilValue } from 'recoil';

import { ProjectAssignmentsListenerKaeplaAdmins } from './ProjectAssignmentsListenerKaeplaAdmins.js';
import { ProjectAssignmentsListenerUsers } from './ProjectAssignmentsListenerUsers.js';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState.js';
import { logger } from '../helpers/logger.js';

export const ProjectAssignmentsListener = () => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);

  logger.log('ProjectAssignmentsListener renders');

  if (kaeplaAssignment) {
    return <ProjectAssignmentsListenerKaeplaAdmins />;
  }

  return <ProjectAssignmentsListenerUsers />;
};
