// <?xml version="1.0" encoding="utf-8"?>
// <svg viewBox="184.7418 144.3923 86.0931 77.7517" xmlns="http://www.w3.org/2000/svg">
//   <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 183.7982940673828, 143.67337036132812)">
//     <g fill="#000000" fill-rule="nonzero">
//       <path d="M86.89,41.08 C86.1779512,35.0739756 83.0841444,29.6053696 78.3028954,25.9014528 C73.5216464,22.1975359 67.4534003,20.5685234 61.46,21.38 C57.0395762,9.03684169 45.3677543,0.779277416 32.2570665,0.719223242 C19.1463786,0.659821199 7.39980508,8.81069874 2.86710412,21.1130695 C-1.66559684,33.4154402 1.98463693,47.2391368 12,55.7 C12.6823773,56.276984 13.6207372,56.4460952 14.4616086,56.1436311 C15.30248,55.841167 15.9181145,55.1130791 16.0766086,54.2336311 C16.2351027,53.354183 15.9123773,52.4569839 15.23,51.88 C6.72393129,44.6860877 3.70693715,32.8944495 7.7126847,22.4992792 C11.7184322,12.1041089 21.868033,5.38617837 33.0020108,5.76049888 C44.1359887,6.13481939 53.8114724,13.5192625 57.11,24.16 C57.6310646,25.8629801 59.3687766,26.8838859 61.11,26.51 C70.2186709,24.701533 79.1606508,30.2977875 81.5156478,39.2806839 C83.8706448,48.2635803 78.824361,57.5270617 70,60.42 C68.823239,60.7981511 68.0994568,61.9794953 68.29698,63.1996385 C68.4945031,64.4197817 69.5540347,65.3124337 70.79,65.3001283 C71.055461,65.3008439 71.3191619,65.2568937 71.57,65.17 C81.7164962,61.7548304 88.0998849,51.7172434 86.89,41.08 Z"/>
//       <path d="M55.5,55.58 L38.12,55.58 L38.12,51.32 C38.1735814,49.8398657 38.6952626,48.4148708 39.61,47.25 C40.5694591,46.217055 41.8686274,45.5639212 43.27,45.41 C44.5669029,45.3036613 45.8533868,45.7133238 46.85,46.55 C47.9919377,47.4757335 48.7766948,48.7695222 49.07,50.21 C49.3461424,51.5658591 50.6691409,52.4411424 52.025,52.165 C53.3808591,51.8888576 54.2561424,50.5658591 53.98,49.21 C53.438686,46.561701 51.9707868,44.1932066 49.84,42.53 C47.7270345,40.8106052 44.9770731,40.0821386 42.29,40.53 C39.6739098,40.8972307 37.2904742,42.2316691 35.61,44.27 C34.0413437,46.2975989 33.1681555,48.7768923 33.12,51.34 L33.12,55.6 L32.44,55.6 C30.9286553,55.5837766 29.4744798,56.1769925 28.4057361,57.2457361 C27.3369925,58.3144798 26.7437766,59.7686553 26.7596758,61.28 L26.7596758,72.79 C26.7437766,74.3013447 27.3369925,75.7555202 28.4057361,76.8242639 C29.4744798,77.8930075 30.9286553,78.4862234 32.44,78.4703242 L55.5,78.4703242 C57.0113447,78.4862234 58.4655202,77.8930075 59.5342639,76.8242639 C60.6030075,75.7555202 61.1962234,74.3013447 61.18,72.79 L61.18,61.5 C61.386238,60.1806735 61.0227622,58.835813 60.18,57.8 C59.0362197,56.3900512 57.3155305,55.5738268 55.5,55.58 L55.5,55.58 Z M56.18,61.26 L56.18,72.79 C56.1886539,72.9728631 56.1197945,73.1508976 55.9903461,73.2803461 C55.8608976,73.4097945 55.6828631,73.4786539 55.5,73.47 L32.44,73.47 C32.2571369,73.4786539 32.0791024,73.4097945 31.9496539,73.2803461 C31.8202055,73.1508976 31.7513461,72.9728631 31.76,72.79 L31.76,61.26 C31.7481081,61.0762645 31.8159229,60.8963078 31.9461154,60.7661154 C32.0763078,60.6359229 32.2562645,60.5681081 32.44,60.58 L55.5,60.58 C55.7649853,60.5677395 56.0239846,60.661267 56.22,60.84 C56.1963083,60.9788195 56.1829383,61.119204 56.18,61.26 L56.18,61.26 Z"/>
//     </g>
//   </g>
// </svg>
import { SvgIcon } from '@mui/material';

export const SamlSso = () => (
  <SvgIcon>
    <path
      stroke="null"
      id="svg_3"
      d="m23.72638,12.62589c-0.19501,-1.64488 -1.04232,-3.14257 -2.35176,-4.15697c-1.30945,-1.01439 -2.97137,-1.46054 -4.61279,-1.2383c-1.21063,-3.38044 -4.40721,-5.64195 -7.99785,-5.6584c-3.59064,-0.01627 -6.80769,2.21602 -8.04907,5.58529c-1.24138,3.36927 -0.24168,7.15518 2.50124,9.47237c0.18688,0.15802 0.44387,0.20434 0.67416,0.1215c0.23029,-0.08284 0.39889,-0.28224 0.4423,-0.52309c0.04341,-0.24086 -0.04498,-0.48657 -0.23186,-0.64459c-2.32957,-1.97021 -3.15584,-5.1996 -2.05878,-8.04654c1.09706,-2.84694 3.87674,-4.68679 6.92602,-4.58427c3.04928,0.10252 5.69912,2.1249 6.60249,5.0391c0.1427,0.4664 0.61862,0.746 1.09549,0.6436c2.4946,-0.49529 4.94356,1.03737 5.58852,3.49752c0.64496,2.46016 -0.73707,4.99716 -3.15381,5.78945c-0.32228,0.10356 -0.5205,0.4271 -0.46641,0.76126c0.0541,0.33416 0.34427,0.57863 0.68277,0.57526c0.0727,0.00019 0.14492,-0.01184 0.21362,-0.03564c2.77884,-0.93532 4.52706,-3.68433 4.19571,-6.59756z"
    />
    <path
      stroke="null"
      id="svg_4"
      d="m15.12956,16.59702l-4.75988,0l0,-1.16669c0.01467,-0.40537 0.15755,-0.79563 0.40807,-1.11466c0.26277,-0.28289 0.61857,-0.46177 1.00237,-0.50392c0.35518,-0.02912 0.70752,0.08307 0.98046,0.31221c0.31274,0.25353 0.52767,0.60786 0.60799,1.00237c0.07563,0.37133 0.43796,0.61105 0.80929,0.53542c0.37133,-0.07563 0.61105,-0.43796 0.53542,-0.80929c-0.14825,-0.72529 -0.55027,-1.37396 -1.13383,-1.82946c-0.57868,-0.47089 -1.33182,-0.6704 -2.06773,-0.54774c-0.71647,0.10057 -1.36923,0.46604 -1.82946,1.02428c-0.42961,0.5553 -0.66875,1.23431 -0.68194,1.93627l0,1.16669l-0.18623,0c-0.41391,-0.00444 -0.81217,0.15802 -1.10487,0.45072c-0.2927,0.2927 -0.45516,0.69096 -0.45081,1.10487l0,3.15226c-0.00435,0.41391 0.15811,0.81217 0.45081,1.10487c0.2927,0.2927 0.69096,0.45516 1.10487,0.45081l6.31547,0c0.41391,0.00435 0.81217,-0.15811 1.10487,-0.45081c0.2927,-0.2927 0.45516,-0.69096 0.45072,-1.10487l0,-3.09201c0.05648,-0.36133 -0.04306,-0.72965 -0.27387,-1.01332c-0.31325,-0.38614 -0.7845,-0.60968 -1.28172,-0.60799l0,0zm0.18623,1.55559l0,3.15774c0.00237,0.05008 -0.01649,0.09884 -0.05194,0.13429c-0.03545,0.03545 -0.08421,0.05431 -0.13429,0.05194l-6.31547,0c-0.05008,0.00237 -0.09884,-0.01649 -0.13429,-0.05194c-0.03545,-0.03545 -0.05431,-0.08421 -0.05194,-0.13429l0,-3.15774c-0.00326,-0.05032 0.01531,-0.0996 0.05097,-0.13526c0.03566,-0.03566 0.08494,-0.05423 0.13526,-0.05097l6.31547,0c0.07257,-0.00336 0.1435,0.02226 0.19719,0.07121c-0.00649,0.03802 -0.01015,0.07646 -0.01095,0.11503l0,0z"
    />
  </SvgIcon>
);
