export const sequence1en = [
  3000,
  '',
  'let me think',
  1000,
  'let me think, let me think',
  3000,
  '',
  3000,
  'this might take a while',
  5000,
  'this might take a while, need to consult some resources',
  4000,
  '',
  3000,
  'maybe a sip of coffee?',
  3000,
  'maybe a sip of coffee? tea?',
  6000,
  '',
  3000,
  'I am on it, hold on',
  2000,
  'I am on it, nearly done',
  1000,
  'I am on it, promise',
  8000,
  '',
  3000,
  'just a few seconds more',
];

export const sequence2en = [
  2000,
  '',
  2000,
  'hold on... give me a moment',
  4000,
  '',
  3000,
  'I need to gather some information',
  5000,
  '',
  2000,
  'almost there',
  3000,
  '',
  2000,
  'just a little longer',
  4000,
  '',
  3000,
  'I`m working on it',
  3000,
  '',
  2000,
  'hold on, I`m almost finished',
];

export const sequence3en = [
  2000,
  'sec please, need to gather some information',
  5000,
  '',
  2000,
  'almost there, just need to find the secret sauce',
  3000,
  '',
  2000,
  'just a little longer, I promise it will be worth it',
  4000,
  '',
  3000,
  'I`m working on it',
  '',
  3000,
  'I`m working on it, like a squirrel on a mission',
  3000,
  '',
  2000,
  'Oops, I tripped on a banana peel. But don`t worry, I`m still working on it!',
];

export const sequence4en = [
  2000,
  '',
  'let me check',
  3000,
  '',
  2000,
  'give me a moment',
  4000,
  '',
  3000,
  'give me a moment, I need to gather some information',
  5000,
  '',
  2000,
  'almost there',
  '',
  2000,
  'almost there, just need to find the secret sauce',
  3000,
  '',
  2000,
  'hold on, I`m almost finished, just need to add some magic',
];
