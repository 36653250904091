import { getProject } from './getProject.js';
import { KaeplaProject } from '../kaeplaTypes/Application/KaeplaProject.js';

interface Options {
  projectIds: string[];
}

export const getProjectsFromProjectIds = async ({ projectIds }: Options) => {
  if (projectIds.length === 0) return;
  const data: KaeplaProject[] = [];
  const uniqueProjectIds = projectIds.filter((v, index, a) => a.indexOf(v) === index);
  if (uniqueProjectIds.length === 0) return data;
  const projects = await Promise.all(uniqueProjectIds.map(async (id) => getProject({ id })));
  if (projectIds.length === 0) return;
  return projects
    .filter((c) => c !== undefined)
    .filter((c) => c.name !== undefined)
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));
};
