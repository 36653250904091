export const getNamesFromEmail = (email: string) => {
  let firstName = '';
  let lastName = '';
  let displayName = 'n/a';
  let acronym = '';
  const match = email.match(/([A-Za-z]+)\.([A-Za-z]+)(?:\.([A-Za-z]+))?\.\d+@[^\s@]+/gm);
  if (match) {
    firstName = match[1];
    lastName = match[2];
  } else {
    firstName = email.replaceAll('.', '').charAt(0).toUpperCase();
    lastName = email.replaceAll('.', '').charAt(1).toUpperCase();
    const parts = email.split('@');
    if (parts?.[0]) {
      const names = parts[0].split('.');
      if (names.length === 2) {
        firstName = names[0].charAt(0).toUpperCase() + names[0].slice(1);
        lastName = names[1].charAt(0).toUpperCase() + names[1].slice(1);
      }
    }
    acronym = `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  }
  displayName = `${firstName} ${lastName}`;
  acronym = `${displayName.split(' ')[0][0].toUpperCase()}${displayName
    .split(' ')[1][0]
    .toUpperCase()}`;
  return { firstName, lastName, displayName, acronym };
};
