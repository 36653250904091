import { Timestamp, FieldValue } from 'firebase/firestore';

import { isFirestoreTimestamp } from './isFirestoreTimestamp.js';

export const mapUndefinedToNullValue = <T>(data: T): Partial<T> => {
  if (data === null) {
    return data;
  }

  if (data === undefined) {
    return null as unknown as T;
  }

  if (typeof data === 'object' && !Array.isArray(data)) {
    if (
      isFirestoreTimestamp(data as Record<string, unknown>) ||
      data instanceof Timestamp ||
      data instanceof FieldValue
    ) {
      return data;
    }

    return mapRecordToNull(data as Record<string, unknown>) as T;
  }

  if (Array.isArray(data)) {
    return mapArrayToNull(data);
  }

  return data;
};

const mapRecordToNull = <T extends Record<string, unknown>>(data: T): T => {
  const result = {} as Record<string, unknown>;
  Object.entries(data).forEach(([key, value]) => {
    result[key] = mapUndefinedToNullValue(value);
  });

  return result as T;
};

const mapArrayToNull = <T extends unknown[]>(data: T): T => {
  return data.map((value) => mapUndefinedToNullValue(value)) as T;
};
