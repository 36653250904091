import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement, useEffect, useRef } from 'react';

import { ThreadMessage } from '../../../services/kaeplaTypes';
import {
  CONVERSATIONAL_UI_HEIGHT,
  CONVERSATIONAL_UI_OFFSET_SM,
  CONVERSATIONAL_UI_OFFSET_SX,
} from '../lib/constants';

interface Options {
  conversation: ReactElement;
  stats: ReactElement;
  aiMessages: ThreadMessage[];
  message: string;
  processing: boolean;
}

export const AIAssistantScrollWrapper = ({
  conversation,
  stats,
  message,
  aiMessages,
  processing,
}: Options) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(() => {
    if (aiMessages.length > 0 || processing) {
      reference.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [message, aiMessages.length, processing]);

  const reference = useRef<HTMLDivElement>(null);
  const responsiveOffset = smUp ? CONVERSATIONAL_UI_OFFSET_SM : CONVERSATIONAL_UI_OFFSET_SX;
  const offsetHeight = (document?.getElementById('root')?.offsetHeight ?? 0) - responsiveOffset;
  return (
    <Box height={offsetHeight ?? CONVERSATIONAL_UI_HEIGHT} sx={{ overflowY: 'scroll' }}>
      {conversation}
      {stats}
      <Box sx={{ mt: 1 }} ref={reference} />
    </Box>
  );
};
