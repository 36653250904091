import localForage from 'localforage';
import { AtomEffect } from 'recoil';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type JsonParsable = Record<string, any> | number | string | boolean | undefined;

type AtomEffectParameters<T> = Parameters<AtomEffect<T>>[0];

/**
 * Presist a recoil state to IndexedDB with localForage
 *
 * @see https://recoiljs.org/docs/guides/atom-effects/#asynchronous-setself
 */
export const localForageEffect = <T extends JsonParsable>({
  node,
  setSelf,
  onSet,
}: AtomEffectParameters<T>) => {
  const key = `recoil-${node.key}`;

  void (async () => {
    const value = await localForage.getItem<string | null>(key);
    if (value !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setSelf(JSON.parse(value));
    }
  })();

  onSet((newValue) => {
    if (newValue === undefined) return;
    void localForage.setItem(key, JSON.stringify(newValue));
  });
};
