import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { SamlSsoAuthenticationFlow } from './AuthenticationFlows/SamlSsoAuthenticationFlow.js';
import { KaeplaCustomerAuthenticationMethod } from '../../../../services/kaeplaTypes/Application/KaeplaCustomerAuthenticationMethod.js';
import { brandingState } from '../../../../services/recoil/persistent/brandingState.js';

export const AuthenticationFlow = () => {
  const branding = useRecoilValue(brandingState);
  switch (branding?.authenticationConfiguration?.preferredMethod) {
    case KaeplaCustomerAuthenticationMethod.SAML_SSO: {
      return <SamlSsoAuthenticationFlow />;
    }
    default: {
      return <Box>Unknown authentication method</Box>;
    }
  }
};
