import { WidgetWithData } from './AiWidgetPreview';
import { AiScalarWidget } from '../AiWidgets/AiScalarWidget';
import { AiTableWidget } from '../AiWidgets/AiTableWidget';

export const AiWidgetPerType = ({ widget }: { widget: WidgetWithData }) => {
  switch (widget.type) {
    case 'scalar': {
      return <AiScalarWidget widget={widget} />;
    }
    case 'table': {
      return <AiTableWidget widget={widget} />;
    }
    case 'chart': {
      return <div>Chart NOT implemented yet</div>;
    }
    default: {
      return <div>Unknown widget type</div>;
    }
  }
};
