import AppleIcon from '@mui/icons-material/Apple';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailLinkIcon from '@mui/icons-material/LinkOutlined';
import PasswordIcon from '@mui/icons-material/Password';

import { Google } from './Google';
import { Microsoft } from './Microsoft';
import { SamlSso } from './SamlSso';

export interface AuthProviderIconProperties {
  providerId: string;
}

export const AuthProviderIcon = ({ providerId }: AuthProviderIconProperties) => {
  switch (providerId) {
    case 'microsoft.com': {
      return <Microsoft />;
    }
    case 'google.com': {
      return <Google />;
    }
    case 'apple.com': {
      return <AppleIcon />;
    }
    case 'github.com': {
      return <GitHubIcon />;
    }
    case 'password': {
      return <PasswordIcon />;
    }
    case 'emailLink': {
      return <EmailLinkIcon />;
    }
    case 'SamlSso': {
      return <SamlSso />;
    }
    default: {
      return null;
    }
  }
};
