import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement } from 'react';

// eslint-disable-next-line import/no-unassigned-import
import '../lib/scrollShadow.css';
import { CONVERSATIONAL_UI_OFFSET_SM, CONVERSATIONAL_UI_OFFSET_SX } from '../lib/constants';

interface Options {
  scrollWrapper: ReactElement;
  askQuestion: ReactElement;
}

export const AIAssistantUI = ({ scrollWrapper, askQuestion }: Options) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const responsiveOffset = smUp ? CONVERSATIONAL_UI_OFFSET_SM : CONVERSATIONAL_UI_OFFSET_SX;
  const offsetHeight = (document?.getElementById('root')?.offsetHeight ?? 0) - responsiveOffset;
  return (
    <Box id="ai-assistant" pt={0}>
      <Box height={offsetHeight ?? responsiveOffset} sx={{ overflow: 'hidden' }}>
        {scrollWrapper}
      </Box>
      {askQuestion}
    </Box>
  );
};
